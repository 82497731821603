var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"id":"suggestion-details-sidebar","width":"50%","title":"User Details","bg-variant":"white","right":"","backdrop":"","no-header":""},on:{"hidden":function($event){return _vm.resetData()},"shown":function($event){return _vm.getSuggestionData()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.isObjectEmpty(_vm.suggestionInfo) ? 'Edit Suggestion': 'Add Suggestion')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"suggestion-form"},[_c('b-form',{staticClass:"p-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title","label-for":"Title"}},[_c('b-form-input',{attrs:{"id":"Title"},model:{value:(_vm.suggestionDetail.title),callback:function ($$v) {_vm.$set(_vm.suggestionDetail, "title", $$v)},expression:"suggestionDetail.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"suggestion-type"}},[_c('v-select',{attrs:{"id":"suggestion-type","options":[ { key: 1, label: 'LOCATION'},{ key: 0, label: 'TOUR'}],"label":"label","reduce":function (option) { return option.key; }},model:{value:(_vm.suggestionDetail.type),callback:function ($$v) {_vm.$set(_vm.suggestionDetail, "type", $$v)},expression:"suggestionDetail.type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Order","label-for":"Order"}},[_c('b-form-input',{attrs:{"id":"Order"},model:{value:(_vm.suggestionDetail.order_id),callback:function ($$v) {_vm.$set(_vm.suggestionDetail, "order_id", $$v)},expression:"suggestionDetail.order_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"my-2"},[_vm._v(" Tours under "+_vm._s(_vm.suggestionDetail.title)+" ")]),_c('validation-provider',{attrs:{"name":"tour","rules":_vm.suggestionDetail.tours?!_vm.suggestionDetail.tours.length?'required':'':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tour","label-for":"tour"}},[_c('vue-autosuggest',{attrs:{"input-props":{id:'autosuggest__input',class:'form-control', placeholder:'Search..'},"suggestions":[{ data: _vm.suggestions }],"limit":10},on:{"focus":function($event){return _vm.debounceSearch(0, _vm.getTourList)},"input":function($event){return _vm.debounceSearch(0, _vm.getTourList)},"selected":_vm.getSelectedTour},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,true),model:{value:(_vm.tourQuery),callback:function ($$v) {_vm.tourQuery=$$v},expression:"tourQuery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-table',{staticClass:"order-table position-relative",attrs:{"id":"tourTokenizer","items":_vm.suggestionDetail.tours,"fields":_vm.tableColumns,"primary-key":"id","empty-text":"No matching records found","responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(input)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(data.item.order_id),callback:function ($$v) {_vm.$set(data.item, "order_id", $$v)},expression:"data.item.order_id"}})]}},{key:"cell(cancel)",fn:function(data){return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function($event){return _vm.removeTour(data.item.id)}}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isBtnLoading},on:{"click":_vm.validateForm}},[(_vm.isBtnLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }