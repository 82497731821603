<template>
  <b-sidebar
    id="suggestion-details-sidebar"
    ref="sidebar"
    width="50%"
    title="User Details"
    bg-variant="white"
    right
    backdrop
    no-header
    @hidden="resetData()"
    @shown="getSuggestionData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isObjectEmpty(suggestionInfo) ? 'Edit Suggestion': 'Add Suggestion' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- form -->
      <validation-observer ref="suggestion-form">
        <b-form
          class="p-1"
          @submit.prevent
        >
          <b-row>
            <!-- Title -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="Title"
                >
                  <b-form-input
                    id="Title"
                    v-model="suggestionDetail.title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Sugestion Type -->
            <b-col md="6">
              <b-form-group
                label="Type"
                label-for="suggestion-type"
              >
                <v-select
                  id="suggestion-type"
                  v-model="suggestionDetail.type"
                  :options="[ { key: 1, label: 'LOCATION'},{ key: 0, label: 'TOUR'}]"
                  label="label"
                  :reduce="option => option.key"
                />
              </b-form-group>
            </b-col>
            <!-- Order -->
            <b-col md="6">
              <b-form-group
                label="Order"
                label-for="Order"
              >
                <b-form-input
                  id="Order"
                  v-model="suggestionDetail.order_id"
                />
              </b-form-group>
            </b-col>
            <!-- Tour -->
            <b-col cols="12">
              <h5 class="my-2">
                Tours under {{ suggestionDetail.title }}
              </h5>
              <validation-provider
                #default="{ errors }"
                name="tour"
                :rules="suggestionDetail.tours?!suggestionDetail.tours.length?'required':'':''"
              >
                <b-form-group
                  label="Tour"
                  label-for="tour"
                >
                  <vue-autosuggest
                    v-model="tourQuery"
                    :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Search..'}"
                    :suggestions="[{ data: suggestions }]"
                    :limit="10"
                    @focus="debounceSearch(0, getTourList)"
                    @input="debounceSearch(0, getTourList)"
                    @selected="getSelectedTour"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- order table -->
              <b-table
                id="tourTokenizer"
                :items="suggestionDetail.tours"
                :fields="tableColumns"
                primary-key="id"
                empty-text="No matching records found"
                responsive
                show-empty
                class="order-table position-relative"
              >
                <template #cell(input)="data">
                  <b-form-input
                    v-model="data.item.order_id"
                    type="text"
                  />
                </template>
                <template #cell(cancel)="data">
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="removeTour(data.item.id)"
                  />
                </template>
              </b-table>
            </b-col>
            <!-- Submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isBtnLoading"
                @click="validateForm"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'SuggestionForm',
  components: {
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    suggestionInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isBtnLoading: false,
      suggestionDetail: {},
      copySuggestionDetail: {},
      tourQuery: '',
      suggestions: [],
      tableColumns: [
        {
          key: 'input', label: 'order',
        },
        { key: 'name' },
        {
          key: 'cancel', label: '',
        },
      ],
      required,
    }
  },
  methods: {
    getTourList() {
      const search = this.tourQuery ? `?search=${this.tourQuery}` : ''
      api.getData(`tour/admin_services/tour_list/${search}`, true)
        .then(response => {
          if (response.data.status) {
            this.suggestions = response.data.result.results
          }
        }).catch(() => {
        })
    },
    formatTourData() {
      const formData = { ...this.suggestionDetail }
      const tourOrder = []
      this.suggestionDetail.tours.forEach(tour => {
        const tourData = { tour_id: tour.id, order_id: tour.order_id }
        tourOrder.push(tourData)
      })
      formData.tours = this.suggestionDetail.tours.map(tour => tour.id)
      formData.tour_order = tourOrder
      return formData
    },
    createOrUpdateSuggestion() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/auto_suggestion_create/'
      let apiResponse
      const suggestionExists = this.suggestionDetail.id
      const formData = this.formatTourData()
      if (suggestionExists) {
        url = `tour/admin_services/auto_suggestion_update/${this.suggestionInfo.id}/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        apiResponse = api.postData(url, true, formData)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `suggestion ${suggestionExists ? 'updated' : 'created'} successfully`)
          this.$emit('getSuggestionDetails')
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    removeTour(id) {
      const results = this.suggestionDetail.tours.filter(tour => tour.id !== id)
      this.suggestionDetail.tours = results
    },
    resetData() {
      this.tourQuery = ''
      this.$emit('resetData')
    },
    getSuggestionData() {
      if (this.isObjectEmpty(this.suggestionInfo) !== 0) {
        (this.isObjectEmpty(this.suggestionInfo))
        this.suggestionDetail = JSON.parse(JSON.stringify(this.suggestionInfo))
      } else {
        this.suggestionDetail = {
          order_id: '', title: '', tours: [], type: '',
        }
      }
    },
    getSelectedTour(data) {
      this.tourQuery = data.item.name
      const tour = { id: data.item.id, name: data.item.name, order_id: this.suggestionDetail.tours && this.suggestionDetail.tours.length ? this.suggestionDetail.tours.length + 1 : 1 }
      this.suggestionDetail.tours.push(tour)
    },
    validateForm() {
      this.$refs['suggestion-form'].validate().then(success => {
        if (success) {
          this.createOrUpdateSuggestion()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
.order-table{
  input{
    width: 50px;
    text-align: center;
    padding: 0;
  }
}
.add-tour{
  div{
    flex-grow: 1;
  }
}
</style>
